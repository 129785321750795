import * as React from "react"
import { graphql, Link } from "gatsby"
import ReactMarkdown from "react-markdown"

import Layout from "../components/sections/layout"
import Seo from "../components/sections/seo"
import HeaderImage from "../components/headerImage"

// {main_image !== null &&
//   main_image.map((image, index) => {
//     const { url, alternativeText, height, width } = image
//     return (
//       <LazyLoadImage
//         key={index}
//         alt={alternativeText}
//         height={height}
//         src={url}
//         effect="blur"
//         width={width}
//       />
//     )
//   })}

import styled from "styled-components"
import { fontSize } from "../css/mixins/mixins"
const Breadcrumb = styled.div`
  background-color: whitesmoke;
  padding: 10px 0;
  margin-bottom: 60px;
`

const FactFile = styled.div`
  border-radius: 0;
  padding: 15px 15px 25px;
  margin-bottom: 40px;
  width: 100%;
  border-left: 5px solid #917147;

  p {
    margin-bottom: 5px;
    ${fontSize(20)}

    color: #4c4a4a;
    font-weight: 300;
  }
`

const FactFileTitle = styled.div`
  color: #917147;
  ${fontSize(34)}
  margin-bottom: 10px;
  font-weight: bold;
`

const RelatedBlogs = styled.aside`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 80px;
  margin-bottom: 60px;
`

const RelatedBlogsTitle = styled.h4`
  ${fontSize(22)}
  text-align: center;
  margin-top: 20px;
  margin-bottom: 40px;
  position: relative;
  color: #000;
  flex-basis: 100%;

  @media only screen and (min-width: 930px) {
    ${fontSize(30)}
  }

  &:after {
    content: "";
    position: absolute;
    width: 50px;
    height: 2px;
    bottom: -15px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    background-color: #000;
  }
`

const RelatedBlogArticle = styled.article`
  background-color: #eeeeee;
  border: 2px #e9e9e9 solid;
  margin-bottom: 20px;
  border-radius: 5px;
  transition: 0.5s background-color, 0.5s border;
  overflow: hidden;
  flex-basis: calc(50% - 10px);
  margin: 5px;
  text-align: center;

  &:hover {
    background-color: #3a3a3a;
    border: 2px #917147 solid;
    span {
      text-decoration: underline;
    }
  }
`

const RelatedBlogLink = styled(Link)`
  text-align: center;
  display: block;
  text-decoration: none;
  font-weight: 700;
  padding: 15px;

  h3 {
    position: relative;
    color: #917147;

    &::after {
      position: absolute;
      content: "";
      width: 30px;
      height: 2px;
      background-color: #917147;
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
`

const BreadcrumbLink = styled(Link)`
  text-decoration: none;
  ${fontSize(15)}
  transition: 0.3s color;

  &:hover {
    color: #3a3a3a;

    i {
      border-color: #3a3a3a;
    }
  }
`

const BreadcrumbArrow = styled.i`
  border: solid #917147;
  transition: 0.3s border-color;

  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 4px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  margin-right: 5px;
`

const Blog = ({ data }) => {
  const { strapiBlog } = data
  const {
    title,
    description,
    main_image = "",
    blogs,
    fact_file = "",
    SEO,
  } = strapiBlog

  const url =
    main_image !== null
      ? main_image.url
      : require(`../media/images/basic_layout_placeholder_image_1.png`).default
  const { page_title = title, page_description = "" } = SEO
  return (
    <Layout>
      <Seo title={page_title} description={page_description} />
      <HeaderImage src={url} title={title} />
      <Breadcrumb>
        <div className="container">
          <BreadcrumbLink to="/fitness-journal">
            <BreadcrumbArrow /> Back to Fitness Journal
          </BreadcrumbLink>
        </div>
      </Breadcrumb>
      <div className="container">
        {fact_file !== "" && fact_file !== null && (
          <FactFile>
            <FactFileTitle>Fact File</FactFileTitle>
            <ReactMarkdown children={fact_file} />
          </FactFile>
        )}
        <ReactMarkdown children={description} />
        <RelatedBlogs className="related-blogs">
          {blogs !== null && (
            <>
              <RelatedBlogsTitle>Related fitness journals</RelatedBlogsTitle>
              {blogs.map((blog, index) => {
                const { slug, title } = blog
                return (
                  <RelatedBlogArticle
                    key={index}
                    className={`related-blog related-blog__${title.toLowerCase()}`}
                  >
                    <RelatedBlogLink to={`/fitness-journal/${slug}`}>
                      <h3>{`${title}`}</h3>
                    </RelatedBlogLink>
                  </RelatedBlogArticle>
                )
              })}
            </>
          )}
        </RelatedBlogs>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query MyQuery($slug: String = "") {
    strapiBlog(slug: { eq: $slug }) {
      blogs {
        slug
        title
      }
      SEO {
        page_title
        page_description
      }
      main_image {
        url
      }
      title
      description
      fact_file
    }
  }
`

export default Blog
